<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-card title="Quality Inspection">

    <b-alert
      v-model="showDismissibleAlert"
      variant="danger"
      dismissible
      class="mb-1"
    >
      <div class="alert-body">
        {{error_message}}
      </div>
    </b-alert>

    <b-row class="form_detail">

      <b-col md="3">
              
          <b-form-group
              label="Project Site"
              class="required"

            >
              <b-form-select v-model="site" @change="siteChange" :disabled="id != null">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                
                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                
                
              </b-form-select>
                
            </b-form-group>
          
      </b-col>

      <b-col md="3" >
          
          <b-form-group
              label="Location"
              class="required"

            >
              <b-form-select v-model="location_id" @change="getItemData()" :disabled="id != null">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="l._id" v-for="l in allLocations" :key="l._id">{{l.name | capitalize}}</b-form-select-option>
                
              </b-form-select>
                
            </b-form-group>
          
      </b-col>

      <b-col md="3">
          
          <b-form-group
              label="Inspected By"
              class="required"

            >
              <b-form-input
                placeholder="3 to 50 characters"
                v-model="supervisor_name"
                :disabled="id != null"
                autocomplete="off"
              />
                
            </b-form-group>
          
      </b-col>

      <b-col md="3">
          
          <b-form-group
              label="Accompanied By"
              class=""

            >
              <b-form-input
                placeholder="3 to 50 characters"
                v-model="accompanied_by"
                :disabled="id != null"
                autocomplete="off"
              />
                
            </b-form-group>
          
      </b-col>
    </b-row>
    <b-row class="form_detail mt-1">

      <b-col md="3">
          
          <b-form-group
              label="Total Score"
              class="mobile_tab_margin_bottom_zero"

            >
              <b-form-input
                readonly
                v-model="total_score"
              />
                
            </b-form-group>
          
      </b-col>

      <b-col md="3">
          
          <b-form-group label="Inspection Date Time" class="mobile_tab_margin_bottom_zero">
            <date-picker v-model="inspection_date_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :disabled-date="disabledDate"
              :disabled-time="disabledTime"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="id != null"
            ></date-picker>
          </b-form-group>
          
      </b-col>
      
    </b-row>
  </b-card>

  <!-- new table structure implementation -->

  <div v-if="item != null" class="table-responsive rounded">
    <table class="table">
        <thead>
            <tr>
                <th scope="col" style="width: 5%;">#</th>
                <th scope="col" style="width: 20%;">Unit Name</th>
                <th scope="col" style="width: 7%;">%</th>
                <th scope="col" style="width: 5%;">S.No</th>
                <th scope="col" style="width: 16%;">Element Name</th>
                <th scope="col" style="width: 20%;">Rating</th>
                <th scope="col" style="width: 17%;">Comment</th>
                <th scope="col" style="width: 10%;" v-if="no_photo_reporting == 'no'">Image</th>
            </tr>
        </thead>
        <tbody v-for="(qi,index) in item" :key="index">
          <tr>
            <td>{{index + 1}}</td>
            <td><span class="wordBreak"> {{qi.unit_name}}</span></td>
            <td>{{qi.unit_rating |currency}}</td>
            <td>1</td>
            <td v-if="no_photo_reporting == 'no'">
              <span class="wordBreak">{{qi.elements[0].name}} </span>
            </td>
            <td v-if="no_photo_reporting == 'yes'">
              <span class="wordBreak">{{qi.elements[0].name}} </span>
            </td>
            <td>
              <div v-if="qi_type == 'Star'">
                  <star-rating v-model="qi.elements[0].rating" :max-rating="max" @rating-selected="calRating(index,0)" :increment="0.5" :fixed-points="2" :star-size="28" :show-rating="false" :animate="false" :read-only="disabledOptions" class="star"></star-rating>
                </div>
                <div v-else>
                  <b-form-select v-model="qi.elements[0].rating" @change="calRating(index,0)" :disabled="disabledOptions">
                    <b-form-select-option value="0">N/A</b-form-select-option>
                    <b-form-select-option :value="m" v-for="m in maxArray()" :key="m">{{m}}</b-form-select-option>
                  </b-form-select>
                </div>  
            </td>
            <td>
              <b-form-input v-model="qi.elements[0].comment" :disabled="disabledOptions" autocomplete="off"/>
            </td>
            <td v-if="no_photo_reporting == 'no'">
              <div v-if="disabledOptions == true">
                <b-avatar
                    :ref="'bI' + index + 0"
                    :src="qi.elements[0].image.image != null ? qi.elements[0].image.image : doc_icon"
                    variant="light-warning" size="40px" rounded
                  />
              </div>
              <div v-else>
                <b-media @click="$refs['biInput' +index + 0 ][0].click()">
                    <template #aside>
                      <b-avatar
                        :ref="'bI' + index + 0"
                        :src="qi.elements[0].image.image != null ? qi.elements[0].image.image : doc_icon"
                        variant="light-warning"
                        size="40px" rounded
                      />
                    </template>
                    <div class="d-flex flex-wrap">
                      <input :ref="'biInput' + index + 0" type="file" class="d-none" @input="biInput($event,index,0)">
                    </div>
                  </b-media>
                  <span class="text-danger ml-1" v-if="qi.elements[0].image.image != null" @click="removeImage(index,0)"><feather-icon icon="XCircleIcon" /></span>
                </div>
            </td>
          </tr>

          <tr v-for="(element,k) in qi.elements" v-if="qi.elements.length > 1" :key="k">
            <td v-if="k > 0" class="top_tr_design"></td>
            <td v-if="k > 0" class="top_tr_design"></td>
            <td v-if="k > 0" class="top_tr_design"></td>
            <td v-if="k > 0">{{k + 1}}</td>
            <td v-if="k > 0 && no_photo_reporting == 'no'">{{element.name}}</td>
            <td v-if="k > 0 && no_photo_reporting == 'yes'">{{element.name}}</td>
            <td v-if="k > 0">
              <div v-if="qi_type == 'Star'">
                <star-rating v-model="element.rating" :max-rating="max" @rating-selected ="calRating(index,k)" :increment="0.5" :fixed-points="2" :star-size="28" :show-rating="false" :animate="false" :read-only="disabledOptions" class="star"></star-rating>
              </div>
              <div v-else>
                <b-form-select v-model="element.rating" @change="calRating(index,k)" :disabled="disabledOptions">
                  <b-form-select-option value="0">N/A</b-form-select-option>
                  <b-form-select-option :value="m" v-for="m in maxArray()" :key="m">{{m}}</b-form-select-option>
                </b-form-select>
              </div> 
            </td>
            <td v-if="k > 0">
              <b-form-input :disabled="disabledOptions" v-model="element.comment" autocomplete="off"/>
            </td>
            <td v-if="k > 0 && no_photo_reporting == 'no'">
              <div v-if="disabledOptions == true">
                <b-avatar
                  :ref="'bI' + index + k"
                  :src="element.image.image != null ? element.image.image : doc_icon"
                  variant="light-warning"
                  size="40px"
                  rounded
                />
              </div>
              <div v-else>
                <b-media @click="$refs['biInput' +index + k ][0].click()">
                    <template #aside>
                      <b-avatar
                        :ref="'bI' + index + k"
                        :src="element.image.image != null ? element.image.image : doc_icon"
                        variant="light-warning"
                        size="40px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <input
                        :ref="'biInput' + index + k"
                        type="file"
                        class="d-none"
                        @input="biInput($event,index,k)"
                      >
                    </div>

                    
                  </b-media>

                  <span class="text-danger ml-1" v-if="element.image.image != null" @click="removeImage(index,k)"><feather-icon icon="XCircleIcon" /></span>
              </div>
            </td>
          </tr>
        </tbody>
    </table>
  </div>

  <!-- new table structure implementation ends -->

  <div v-if="item != null">
    <b-card>
      <b-form @submit="formSubmit">
        <!-- <b-row class="mb-1">
            <b-col md="1">
              <h5> # </h5>
            </b-col>
            <b-col md="2">
              <h5> Unit Name </h5>
            </b-col>
            <b-col md="1">
             <h5> % </h5>
            </b-col>
            <b-col md="1">
              <h5> S.No </h5>
            </b-col>
            <b-col md="2" v-if="no_photo_reporting == 'no'">
              <h5> Element Name </h5>
            </b-col>

            <b-col md="3" v-if="no_photo_reporting == 'yes'">
              <h5> Element Name </h5>
            </b-col>

            
            <b-col md="2">
              <h5> Rating </h5>
            </b-col>

            <b-col md="2">
              <h5> Comment </h5>
            </b-col>
            <b-col md="1" v-if="no_photo_reporting == 'no'">
              <h5> Image </h5>
            </b-col>

            
        </b-row>
        <hr>

        <div v-for="(qi,index) in item">

          <b-row class="mb-0">

              <b-col md="1" class="mb-1">
                {{index + 1}}
              </b-col>

              <b-col md="2" class="mb-1">
                <p class="wordBreak"> {{qi.unit_name}} </p>
              </b-col>
              <b-col md="1" class="mb-1">
                {{qi.unit_rating |currency}}
              </b-col>

              
              <b-col md="1" class="mb-1">
                1
              </b-col>
              <b-col md="2" class="mb-1" v-if="no_photo_reporting == 'no'">
                <p class="wordBreak">{{qi.elements[0].name}} </p>
              </b-col>

              <b-col md="3" class="mb-1" v-if="no_photo_reporting == 'yes'">
                <p class="wordBreak">{{qi.elements[0].name}} </p>
              </b-col>
              
              <b-col md="2" class="mb-1">                
                <div v-if="qi_type == 'Star'">
                  <star-rating v-model="qi.elements[0].rating" :max-rating="max" @rating-selected="calRating(index,0)" :increment="0.5" :fixed-points="2" :star-size="28" :show-rating="false" :animate="false" :read-only="disabledOptions" class="star"></star-rating>
                </div>
                <div v-else>
                  <b-form-select v-model="qi.elements[0].rating" @change="calRating(index,0)" :disabled="disabledOptions">
                    <b-form-select-option value="0">N/A</b-form-select-option>
                    <b-form-select-option :value="m" v-for="m in maxArray()" :key="m">{{m}}</b-form-select-option>
                  </b-form-select>
                </div>                
              </b-col>

              <b-col md="2" class="mb-1">
                
                  <b-form-input
                    v-model="qi.elements[0].comment"
                    :disabled="disabledOptions"
                    autocomplete="off"
                  />

              </b-col>

              <b-col md="1" class="mb-1" v-if="no_photo_reporting == 'no'">

                <div v-if="disabledOptions == true">
                  <b-avatar
                      :ref="'bI' + index + 0"
                      :src="qi.elements[0].image.image != null ? qi.elements[0].image.image : doc_icon"
                      variant="light-warning"
                      size="40px"
                      rounded
                    />
                </div>
                <div v-else>
                  <b-media @click="$refs['biInput' +index + 0 ][0].click()">
                      <template #aside>
                        <b-avatar
                          :ref="'bI' + index + 0"
                          :src="qi.elements[0].image.image != null ? qi.elements[0].image.image : doc_icon"
                          variant="light-warning"
                          size="40px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          :ref="'biInput' + index + 0"
                          type="file"
                          class="d-none"
                          @input="biInput($event,index,0)"
                        >
                      </div>

                      
                    </b-media>


                    <span class="text-danger ml-1" v-if="qi.elements[0].image.image != null" @click="removeImage(index,0)"><feather-icon icon="XCircleIcon" /></span>
                  </div>
                  
                    
                    
                  
              </b-col>

              


          </b-row>

          <b-row class="mb-0" v-for="(element,k) in qi.elements" v-if="qi.elements.length > 1" :key="k">
              <b-col md="1" v-if="k > 0" class="mb-1">
                
              </b-col>
              <b-col md="2" v-if="k > 0" class="mb-1">
                
              </b-col>
              <b-col md="1" v-if="k > 0" class="mb-1">
                
              </b-col>
              <b-col md="1" v-if="k > 0" class="mb-1">
                {{k + 1}}
              </b-col>
              <b-col md="2" v-if="k > 0 && no_photo_reporting == 'no'" class="mb-1">
                {{element.name}}
              </b-col>

              <b-col md="3" v-if="k > 0 && no_photo_reporting == 'yes'" class="mb-1">
                {{element.name}}
              </b-col>
              
              <b-col md="2" v-if="k > 0 " class="mb-1">
                <div v-if="qi_type == 'Star'">
                  <star-rating v-model="element.rating" :max-rating="max" @rating-selected ="calRating(index,k)" :increment="0.5" :fixed-points="2" :star-size="28" :show-rating="false" :animate="false" :read-only="disabledOptions" class="star"></star-rating>
                </div>
                <div v-else>
                  <b-form-select v-model="element.rating" @change="calRating(index,k)" :disabled="disabledOptions">
                    <b-form-select-option value="0">N/A</b-form-select-option>
                    <b-form-select-option :value="m" v-for="m in maxArray()" :key="m">{{m}}</b-form-select-option>
                  </b-form-select>
                </div>  
              </b-col>
              <b-col md="2" v-if="k > 0" class="mb-1" >
                <b-form-input
                    :disabled="disabledOptions"
                    v-model="element.comment"
                    autocomplete="off"
                  />
              </b-col>
              <b-col md="1" v-if="k > 0 && no_photo_reporting == 'no'" class="mb-1">
                <div v-if="disabledOptions == true">
                  <b-avatar
                    :ref="'bI' + index + k"
                    :src="element.image.image != null ? element.image.image : doc_icon"
                    variant="light-warning"
                    size="40px"
                    rounded
                  />
                </div>
                <div v-else>
                  <b-media @click="$refs['biInput' +index + k ][0].click()">
                      <template #aside>
                        <b-avatar
                          :ref="'bI' + index + k"
                          :src="element.image.image != null ? element.image.image : doc_icon"
                          variant="light-warning"
                          size="40px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          :ref="'biInput' + index + k"
                          type="file"
                          class="d-none"
                          @input="biInput($event,index,k)"
                        >
                      </div>

                      
                    </b-media>

                    <span class="text-danger ml-1" v-if="element.image.image != null" @click="removeImage(index,k)"><feather-icon icon="XCircleIcon" /></span>
                </div>
                  

                    
              </b-col>

              
          </b-row>
          <hr>

        </div>

        <br> -->


        <b-row>
          <b-col md="12">
              
            <b-form-group
                label="Closing Report"
                class=""

              >
                <b-form-textarea
                  :disabled="disabledOptions"
                  v-model="note"
                />
                  
              </b-form-group>
              
          </b-col>
        </b-row>


        <b-row>
            <b-col
              cols="12"
              class="mb-2 mt-2"
            > 

            
              
              <b-col
              class="mb-2 mobile_pdng_lft_rgt_zero"
              >
                <center>
                  <h4 class="mb-0 align_start_mobile">
                    Signature
                  </h4>
                </center>
                
              </b-col>
           
            

            
              <b-col
                class="mb-2 mt-0 mobile_pdng_lft_rgt_zero"
                style="text-align: center"
              > 
            
                  <b-media class="mb-2 mt-2 desktop_display_block" @click="openSignature('supervisor_signature')" v-if="supervisorSign.image == null">
                    <template #aside>
                      <b-avatar
                        ref="supervisorS"
                        :src="doc_icon"
                        variant="light-danger"
                        size="200px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <input
                        ref="supervisorSInput"
                        type="file"
                        class="d-none"
                        @input="supervisorImageUpload"
                      >
                    </div>

                    
                  </b-media>

                  <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                  
                  <center class="single_img_danger_btn">
                    <b-button
                        variant="danger"
                        class="mt-1 ml-2"
                        v-if="supervisorSign.image != null"
                        @click="removeSign('supervisor')"
                        :disabled="disabledOptions"
                      >
                      <feather-icon icon="XIcon" />
                      
                    </b-button>
                  </center>
                
                </b-col>
            
            </b-col>
          </b-row>

          <!-- <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="warning"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$router.push({ name:'qm-qi' })"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row> -->

          <b-row class="mt-1">
            <b-col>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class = "mr-1"
                variant="outline-secondary"
                @click="goBack()"
              >
                Cancel
              </b-button>

              <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(id)" v-if="report_status == 'draft'">
                Discard Draft
              </b-button>  

              <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "primary" class = "mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined' || report_status == 'draft'">
                  {{ id == null ? 'Save as Draft' : 'Update Draft'}}
              </b-button>   

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1"
                type="submit"
                @click="status = 'completed'"
              >
                {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
              </b-button>
              <!-- {{ ledit }} - {{ typeof $route.params.id != 'undefined' && ledit == false && report_status != 'draft' }} -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                v-if="typeof $route.params.id != 'undefined' && ledit == false && report_status != 'draft'"
                @click="openSignature('approver')"
              >
                {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
              </b-button>

            </b-col>
          </b-row>


      </b-form>
    </b-card>

    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              :disabled-time="disabledTime"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              :disabled-time="disabledTime"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined' && report_status != 'draft'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        
        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

  </div>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb, BFormTextarea, BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
var moment = require('moment-timezone');
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import StarRating from 'vue-star-rating'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb,
    BFormTextarea,
    Datepicker,
    flatPickr,
    BFormDatepicker,
    DatePicker,
    StarRating
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inspection_date_time: moment().format('DD MMM YYYY HH:mm'),
      id: null,
      location_id:'',
      allLocations:[],
      supervisor_name:this.$store.getters.currentUser.full_name,
      accompanied_by:'',
      total_score:0,
      site:'',
      sites:[],
      item:null,
      no_photo_reporting:null,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      showDismissibleAlert:false,
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,
      note:'',
      
      disabledOptions:false,
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,

      status: 'draft',
      report_status:null,
      qi_type : null
      
    }
  },
  methods : {
    maxArray(){
      var increment = 0.5;
      var series = [];

      for (var i = 0; i <= this.max; i += increment) {
          series.push(i);
      }

      return series;
    },

    disabledDate(date){
      var today = moment().tz('Asia/Singapore');
      return date >= today;
    },
    disabledTime(date, currentValue){
      return date >= moment().tz('Asia/Singapore');
    },

    disabledDate2(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Quality Management'
      },{
        to:null,
        text: 'Overview'
      },{
        to:{name:'qm-qi'},
        text: 'Quality Inspection'
      },{
        to:null,
        text: 'Add Quality Inspection'
      }];
      return item;
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },

    siteChange(){
      
      this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      this.location_id = '';
      this.allLocations = [];
      this.item = null;
      

      if (this.site != '') {

        var selectedSite = this.sites.find(item => { return item._id == this.site});
        this.no_photo_reporting = selectedSite.no_photo_reporting;
        this.allLocationsList();
      }
    },
    allLocationsList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site,
             type:'QI'
           },
           api: '/api/qm-all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allLocations = this.$store.getters.getResults.data;
                return this.allLocations;
            }
        });
    },
    getItemData(){
      return this.$store.dispatch(POST_API, {
           data:{
             location_id:this.location_id != '' ? this.location_id : null,
             site:this.site,
             id:this.id
           },
           api: '/api/location-qi-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                var draft = this.$store.getters.getResults.data;

                if(draft.with_data){
                  this.item           = draft.item;
                  this.max            = parseInt(draft.max);
                  this.accompanied_by = draft.accompanied_by
                  this.total_score    = draft.total_score
                  this.note           = draft.notes
                  this.id             = draft.id
                  this.supervisor_name = draft.inspected_by
                  this.qi_type        = draft.qi_type

                  var role = this.$store.getters.currentUser.role;

                  if( this.$route.params.id != undefined ){
                    this.site = draft.site

                    var selectedSite = this.sites.find(item => { return item._id == this.site});

                    this.no_photo_reporting = selectedSite.no_photo_reporting;

                    this.allLocationsList().then(() => {
                      this.location_id = draft.location
                    })
                  }

                  
                  // else if(((draft.level_one_user_role != role) && (draft.level_two_user_role != role) && (draft.level_three_user_role != role))){
                  //     this.ledit = true;
                  // }
                  
                  if(draft.supervisor_signature){
                    //this.tempSign.image       = draft.supervisor_signature
                    this.supervisorSign.image = draft.supervisor_signature
                  }

                  if (draft.status == 'draft') {
                    this.disabledOptions = false;
                    this.report_status = 'draft';
                  }else{
                    
                    // if (draft.reject_to == role && ((draft.level_one_user_role != role) && (draft.level_two_user_role != role) && (draft.level_three_user_role != role))) {
                    //   this.ledit = true;
                    // } else if (((role == 'administrator')) && ((draft.level_user_status != null) && (draft.level_user_status.role == ''))) {
                    //   this.ledit = true;
                    // } else if((draft.level_one_user_role != role) && (draft.level_two_user_role != role) && (draft.level_three_user_role != role)) {
      
                    //   this.ledit = true;
                    //   this.disabledOptions = false;
                    // }else{
                    //   this.disabledOptions = true;
                    // }

                    if(role =='administrator'){
                      this.ledit = false;
                      this.disabledOptions = false;
                    } else if ((draft.level_one_user_role != role) && (draft.level_two_user_role != role) && (draft.level_three_user_role != role)) {
                      this.ledit = true;
                      this.disabledOptions = false;
                    }else{
                      this.disabledOptions = true;
                    }
                    
                  }


                } else {
                  this.item    = draft.item;
                  this.max     = parseInt(draft.max);
                  this.qi_type = draft.qi_type
                }

            }
        });
    },
    calRating(i,k){

      var overall = 0;
      var overalltotal = 0;

       for(var l = 0; l < this.item.length; l++){
        
        var e_total = 0;
        var e = 0;

        for(var m = 0; m < this.item[l].elements.length; m++ ){
            
            if (this.item[l].elements[m].rating > 0) {
              e = e+1;
            }


            e_total = e_total + parseInt(this.item[l].elements[m].rating);

        }

        if (e > 0) {

           var total = (e_total * 100)/(e * this.max);
         }else{
           var total = 0;
         }



        //var total = (e_total * 100)/(e * this.max);

        this.item[l].unit_rating = parseFloat(total).toFixed(2);
        
        
        if (parseFloat(total).toFixed(2) > 0) {
          overalltotal = overalltotal + 1;
        }

        overall = parseFloat(overall) + parseFloat(this.item[l].unit_rating);

       }
       
       //console.log(overall, overalltotal);

       if (overalltotal > 0) {
          var overallTotal = (parseFloat(overall).toFixed(2) * 100) / (overalltotal * 100);
       
          this.total_score = overallTotal.toFixed(2); 
       }else{
          this.total_score = 0; 

       }
       

       //this.updateQData('in_progress');
    },
    biInput(event,index,key){
      console.log(event,index);

      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['biInput'+index+key][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['biInput'+index+key][0].value=null;
              

          } else {
              reader.onload = (e) => {

                this.item[index].elements[key].image.image = e.target.result;
                this.item[index].elements[key].image.name = image.name;

            }
          }
      }
    },
    removeImage(index,k){
      
      this.item[index].elements[k].image = {
          image:null,
          name:''
        }
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'signature.png',
                  }

                  this.supervisorSign = {
                    image:data.default_signature,
                    name:'signature.png',
                    type: 'supervisor',
                    default:'yes'
                  };

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor_signature') {

         this.$refs['supervisorSInput'].click();
      }

      /*if (this.openedSignModel == 'inspected_by') {

         this.$refs['inspectedSInput'].click();
      }*/

      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
      
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor_signature') {
          
          this.supervisorSign.image = data;
          this.supervisorSign.name = 'siganture.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';

          
        }else{

          this.supervisorSign.image = data;
          this.supervisorSign.name = 'signature.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';
          this.status = 'approved';
          this.actualForm();
          

        }

        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){
      
      if (this.openedSignModel == 'supervisor_signature') {
                    
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        
      }else{

        this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes',
            type:'supervisor'
          }
          this.status = 'approved';
          this.actualForm();
      }

      this.$refs['signatureModel'].hide();
    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type = 'supervisor';
                this.supervisorSign.default = 'no';

            }
          }
      }
      

    },
    removeSign(sign){

      this.supervisorSign.image = null;
      this.supervisorSign.name = '';
      this.supervisorSign.default = 'no';
    },
    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
      
    },

    actualForm(){
      return this.$store.dispatch(POST_API, {
         data:{
           inspection_date_time : moment(this.inspection_date_time, 'DD MMM YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
           site                 : this.site,
           location_id          : this.location_id,
           inspected_by         : this.supervisor_name,
           qi_data              : this.item,
           note                 : this.note,
           supervisor_signature : this.supervisorSign,           
           total_score          : this.total_score,           
           accompanied_by       : this.accompanied_by,
           no_photo_reporting   : this.no_photo_reporting,
           status               : this.status,
           id                   : this.id,
           form_id              : this.$route.params.id,
           approve_signature    : this.supervisorSign,
           approve_remark       : this.approve_remark,
           role                 : this.$store.getters.currentUser.role,
           approve_signature_time : this.approve_signature_time,
           qi_type                : this.qi_type
         },
         api: '/api/mark-complete-qm-qi'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              window.scrollTo(0,0);
          } else {
              this.showDismissibleAlert = false;
              var data = this.$store.getters.getResults.data;
              
              localStorage.setItem('routeCFCheck','no');

              this.successAlert().then((result) => {
                  if (data != null) {
                      window.open(data, '_blank');
                  }
                  this.$router.push({ name:'qm-qi' })
              });
              
          }
      });
    },

    discardItem(id){
      var msg = 'Are you sure want to discard this record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      id     : id,
                      status : 'deleted'
                  },
                  api : "/api/change-qm-qi-report-status",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      localStorage.setItem('routeCFCheck','no');
                      this.$store.getters.getResults.message = 'Report Discard Successfully';
                      this.successAlert()
                      .then(() => {
                          this.flag = true;

                          if(this.$route.params.id){
                            this.$router.push({name:'qm-qi'})
                          }else{
                            this.$router.push({ name:'qm-qi' })
                          }
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },

    // getFormDetails(){
    //   return this.$store.dispatch(POST_API, {
    //        data:{
    //          id:this.$route.params.id
    //        },
    //        api: '/api/get-qm-qi-location-data'
    //     })
    //     .then(() => {
    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.showDismissibleAlert = true;
    //             window.scrollTo(0,0);
    //         } else {
    //             this.showDismissibleAlert = false;
    //             var data  = this.$store.getters.getResults.data;
                
    //             this.id  = data._id;
    //             this.date  = data.checked_date;
    //             this.site  = data.site;
    //             this.checked_by  = data.checked_by;
    //             this.shift  = data.shift;
    //             this.checklistData  = data.checkListData;
    //             this.status = data.status;
    //             this.crew_signature = {
    //                 image   : data.crew_signature,
    //                 name    : 'signature.png',
    //                 type    : 'supervisor',
    //                 default : 'yes'
    //             };
                

    //             var role = this.$store.getters.currentUser.role;

    //             if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
    //                 //this.disabledOptions = false;
    //                 this.ledit = true;
    //             } else if(((role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
    //                 this.ledit = true;
    //             }

    //             this.getShiftData();
    //             this.getBuildings();
    //         }
    //     });
    // },

    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    },
    goBack(){
      this.$router.push({ name: 'qm-qi' })
    }

  },
  mounted(){
    this.allSites().then( () => {
      if(this.$route.params.id){
        //console.log('id hai');
        this.id = this.$route.params.id;
        //this.disabledOptions = true;
        // this.getFormDetails();
        this.getItemData();
      }
    });
    this.useDefault();
  }
}
</script>
